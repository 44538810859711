import { Colors } from '~/src/types/styles'

export enum TypographyVariant {
  xs = 'xs',
  xsMedium = 'xsMedium',
  sm = 'sm',
  smMedium = 'smMedium', // default
  smBold = 'smBold',
  base = 'base',
  baseMedium = 'baseMedium',
  baseBold = 'baseBold',
  baseBolder = 'baseBolder',
  lg = 'lg',
  lgMedium = 'lgMedium',
  lgBold = 'lgBold',
  xlBold = 'xlBold',
  xxlBold = 'xxlBold',
  xxlBolder = 'xxlBolder',
  // new
  xxxlBold = 'xxxlBold',
}

export enum TypographyElement {
  p = 'p',
  span = 'span',
  label = 'label',
  li = 'li',
  h1 = 'h1',
  h2 = 'h2',
  h4 = 'h4',
  NuxtLink = 'NuxtLink',
}

export interface Props {
  as?: `${TypographyElement}`
  variant?: `${TypographyVariant}`
  color?: `text-${Colors}`
  class?: string
  target?: string
}
