<template>
  <transition
    name="slide"
    appear
    :duration="500"
  >
    <div
      class="bg transition-all bg-modal"
      @click="handleClose"
    >
      <div
        v-if="type === 'normal'"
        class="modal bg-white rounded-[10px] overflow-x-hidden overflow-y-auto shadow-xl transform transition-all box-border"
        :class="[
          fullPageView ? 'h-screen' : 'p-6 max-w-[90%] sm:max-w-[70%] md:max-w-[80%] xl:max-w-[90%] max-h-[90vh]',
        ]"
        :style="{ width: fullPageView ? '100%' : width }"
      >
        <div class="flex w-full justify-between h-full">
          <div class="flex flex-col items-center relative w-full overflow-y-auto">
            <div class="w-full flex flex-col gap-2 items-center justify-between">
              <h2 class="font-semibold text-xl text-center flex-1">
                {{ title }}
              </h2>

              <IconBtn
                v-if="closable"
                label="close"
                class="flex-2 absolute right-0 -top-1 z-50"
                @click.prevent="closeModal"
              >
                <MaIcon
                  name="add"
                  class="w-5 !text-secondary rotate-45"
                />
              </IconBtn>
            </div>
            <slot />
          </div>
        </div>
      </div>

      <div
        v-else-if="type === 'settings'"
        class="h-screen bg-white max-w-[472px] overflow-y-auto w-full fixed py-9 px-6 right-0"
      >
        <div class="flex w-full justify-between items-center">
          <div class="flex items-center justify-between w-full">
            <h2 class="font-semibold">
              {{ title }}
            </h2>
            <MaIcon
              name="add"
              class="w-7 ml-auto cursor-pointer rotate-45"
              @click.prevent="closeModal"
            />
          </div>

          <slot name="header" />
        </div>
        <slot />
      </div>

      <div
        v-else-if="type === 'sidebar'"
        class="sidebar"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { modal } from '~/src/composables/core/modals'

const emits = defineEmits(['close'])

const props = defineProps({
  propsModal: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    default: 'normal',
  },
  fullPageView: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '29.5rem',
  },
  closable: {
    type: Boolean,
    default: false,
  },
  closeOnClick: {
    type: Boolean,
    default: true,
  },
})

const handleClose = (e: Event) => {
  if (!props.closeOnClick) return
  if ((e.target as HTMLElement).classList.contains('bg-modal')) return closeModal()
}

const closeModal = () => {
  modal.close(props?.propsModal)
  emits('close')
}
</script>

<style scoped>
.sidebar {
  @apply fixed inset-y-0 left-0;
  width: 280px;
  height: 100vh;
  z-index: 100;
  padding: 1.8rem 0.8rem;
  background: var(--light);
  box-shadow: 5px 0 20px 2px rgb(0 0 0 / 20%);
}
.bg {
  @apply bg-RGB-gray700/[0.7];
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  min-height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  backdrop-filter: blur(2px);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
